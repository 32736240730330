<template>
  <div class='WordSection1'>
    <p class='title'>ROC TOKEN</p>
    <p class='tit'>Total Supply: 100 Million</p>
    <p class='MsoNormal'>First 12-month Circulation Cap: 25 million ROC (ERC-20)</p>
    <div class="list">
      <div class="item" >
        <img src="./img/pic1.png"/> 
        <div>
          <p class="b-text" style="margin-top: 10px"><span></span>Roc Foundation - 67.5 million</p>
          <p class="s-text">35 million ROC issued on Roxe Chain</p>
          <p class="s-text">32.5 million ROC issued on Ethereum</p>
          <p class="b-text" style="margin-top: 30px"><span class="colorDEECFF"></span>Partners, Investors and Dev - 32.5 million</p>
        </div>
      </div>
      <h3>Distribution</h3>
      <div class="item">
        <img src='./img/pic2.png'/> 
        <div>
          <p class="b-text"><span class="colorDEECFF"></span>35 million ROC<em>Roxe Chain</em></p>
          <p class="s-text">15 million ROC for node staking</p>
          <p class="s-text">20 million ROC for account operations</p>
          <p class="b-text colorDEECFF" style="margin-top: 30px"><span></span>65 million ROC<em>ERC-20</em></p>
          <p class="s-text">25 million - release as scheduled during the first year after listing</p>
          <p class="s-text">40 million - release by Daollar protocol triggered by the market price increase starting</p>
        </div>
      </div>
      <h3>Distribution Rules</h3>
      <div class="item">
        <img src='./img/pic3.png'/> 
        <div>
          <p class="b-text color818E9E"><span></span>40% - Roxe Reserve for Daollar protocol and DO</p>
          <p class="b-text color818E9E"><span class="colorFFAE69"></span>20% - Roxe Chain node operations</p>
          <p class="b-text color818E9E"><span class="color214B7D"></span>15% - Team</p>
          <p class="b-text color818E9E"><span class="color74BBF3"></span>15% - Roxe Chain node staking</p>
          <p class="b-text color818E9E"><span class="color469FE5"></span>5% - Fund raising</p>
          <p class="b-text color818E9E"><span class="colorF5DEBE"></span>5% - Marketing </p>
          <p class="color818E9E">from 2nd year after listing. The daily release cap is 50000 ROC. </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.tit{
  font-size: 18px;
  color: #4F4F4F;
  margin-bottom: 10px;
}
.u-header__section {
  background: #000;
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.grid {
  img{
    width: 100%;
  }
  margin-bottom: 30px;
  margin-top: 30px;
}

.WordSection1 {
  padding: 160px 50px 80px;
  background: #fff;
  min-height:700px;
  .title {
    font-family: ParmaPetit;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 27px;
    color: #085DA3;
    margin-bottom: 40px;
  }
  .tit {
    font-family: Louis George Cafe;
    font-size: 18px;
    line-height: 21px;
    color: #4F4F4F;
    margin-bottom: 10px;
  }
  .MsoNormal {
    font-family: Louis George Cafe;
    font-size: 16px;
    line-height: 19px;
    color: #818E9E;
  }
  .list {
    img {
      width: 160px;
      height: 160px;
      margin-right: 100px;
    }
    .item {
      display: flex;
      margin-top: 55px;
      .b-text {
        font-family: Louis George Cafe;
        font-size: 18px;
        line-height: 21px;
        color: #4F4F4F;
        display: flex;
        align-items: center;
        // margin-top: 30px;
        margin-bottom: 8px;
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          background: #1E76BF;
          margin-right: 20px;
        }
        em {
          font-family: Louis George Cafe;
          font-size: 14px;
          line-height: 16px;
          color: #214B7D;
          margin-left: 10px;
          padding: 3px 7px;
          background: #EFF6FF;
          border-radius: 2px;
        }
      }
      
      .s-text {
        font-family: Louis George Cafe;
        font-size: 16px;
        line-height: 19px;
        color: #818E9E;
        padding-left: 30px;
        margin-bottom: 6px;
      }
      .color818E9E {
        color: #818E9E;
        margin-bottom: 10px;
      }
    }
    h3 {
      font-family: ParmaPetit;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 22px;
      color: #085DA3;
      margin-top: 100px;
    }
  }
}
.WordSection1 .list .item .b-text {
  .colorDEECFF {
   background: #DEECFF
 }
  .colorFFAE69 {
   background: #FFAE69
 }
  .color214B7D {
   background: #214B7D
 }
  .color74BBF3 {
   background: #74BBF3
 }
 .color469FE5 {
   background: #469FE5
 }
 .colorF5DEBE {
   background: #F5DEBE
 }
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
    .list {
      .item {
        display:block;
        img {
          margin-bottom: 30px;
        } 
      }
    }
  }
}
</style>
