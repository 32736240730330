import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import disclaimer from "../views/legal/disclaimer.vue";
import legal from "../views/legal/legal.vue";
import burn from '../views/legal/burn.vue';
import rocToken from '../views/legal/rocToken.vue';

const routes = [
  { //legal
    path: '/legal',
    name: 'legal',
    meta: {
      title: ''
    },
    component: legal,
    children: [{ //terms
      path: 'disclaimer',
      name: 'disclaimer',
      meta: {
        title: 'disclaimer'
      },
      component: disclaimer
    },
    {
      path: 'burn/disclaimer',
      name: 'burn',
      meta: {
        title: 'disclaimer'
      },
      component: burn
    },
    {
      path: 'roc/disclaimer',
      name: 'roc disclaimer',
      meta: {
        title: 'disclaimer'
      },
      component: () => import('../views/legal/disclaime.vue')
    },
    {
      path: 'token',
      name: 'rocToken',
      meta: {
        title: 'Roc Token'
      },
      component: rocToken
    }
  ]
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About/about.vue')
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog/blog.vue')
  },
  {
    path: '/supernodes',
    name: 'supernodes',
    component: () => import('../views/Supernodes/supernodes.vue')
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('../views/Resources/resources.vue')
  },
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index/index.vue')
  },
  {
    path: '/do',
    name: 'do',
    component: () => import('../views/DO/do.vue')
  },
  {
    path: '/getDO',
    name: 'getDO',
    component: () => import('../views/ROC/getDo.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('../views/ROC/wallet.vue')
  },
  {
    path: '/balances',
    name: 'balances',
    component: () => import('../views/ROC/balances.vue')
  },
  {
    path: '/getRoc',
    name: 'getRoc',
    component: () => import('../views/ROC/getRoc.vue')
  },
  {
    path: '/sellRoc',
    name: 'sellRoc',
    component: () => import('../views/ROC/sellRoc.vue')
  },
  {
    path: '/',
    name: 'adminview',
    meta: {
    },
    component: () => import('../views/adminview/adminview.vue'),
    children: [
      {
        path: '/adminview/orders',
        name: 'orders',
        meta: {
          title: 'orders'
        },
        component: () => import('../views/adminview/orders.vue'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to,from,savedPosition){
    console.log(to, from, savedPosition, '11111111')
    if(savedPosition){
      return savedPosition;
    }else{
      return {x:0,y:0}
    }
  },
})

export default router
