<template>
  <div id="app">
    <Header :style="{background: this.$route.path == '/roc' ? '#000' : 'transparent'}"></Header>
    <div></div>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../src/components/header.vue";
import Footer from "../src/components/footer.vue";

export default {
  name: "app",
  mounted() {
  },
  components: {
    Header,
    Footer
  },
};
</script>
<style lang="less">
@import "./assets/css/theme.less";
@import "./assets/css/style.less";
</style>
<style lang="less">
.bg-contnet {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 70px);
  background: #f8fafc;
  overflow: hidden;
}
.fa-question-circle {
  color: #ff7500;
  opacity: 0.5;
  font-size: 16px;
  margin-left: 5px;
}
</style>
