<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-2 col-md-12 col-xs-12">
          <img alt="logo" src="../assets/images/roxe_chain.png" class="logo"/>
        </div>
        <div class="col-lg-2 col-md-2 col-xs-6">
          <p>Services</p>
          <div class="f-content">
            <ul class="learn-more">
              <li>
                <a href="https://scan.rocpro.me/#/" target="_blank">Explorer</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-xs-6">
          <p>Daollar</p>
          <div class="f-content">
            <ul class="learn-more">
              <li>
                <a  @click="link('legal/token')" style="cursor:pointer;">ROC</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-xs-6">
          <p>Information</p>
          <div class="f-content">
            <ul class="learn-more">
              <li>
                <a @click="link('about')" style="cursor:pointer;">About Us</a>
              </li>
              <li>
                <a  @click="link('resources')" style="cursor:pointer;">Resources</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-xs-6">
          <p>Partners</p>
          <div class="f-content">
            <ul class="learn-more">
              <li>
                <a @click="link('supernodes')" style="cursor:pointer;">Supernodes</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 col-xs-6">
          <p><a  @click="link('legal/roc/disclaimer')" style="cursor:pointer;">Disclaimer</a></p>
        </div>
        <div class="col-lg-2 col-md-3 col-xs-12">
          <p>Follow Us</p>
          <div class="f-content">
            <div class="community">
              <a href="https://twitter.com/RoxeChain" target="_blank"><img src="./img/twitter.svg"/></a>
              <!-- <img src="./img/facebook.svg"/> -->
              <a href="https://t.me/roxechainglobal" target="_blank"><img src="./img/telegram.svg"/></a>
              <a href="https://medium.com/roxechain" target="_blank"><img src="./img/m.svg"/></a>
              <a href="https://discord.gg/d3mDxd6th7" target="_blank"><img src="./img/Union.svg"/></a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {};
  },
  components: {},
  methods: {
    link (val) {
      this.$router.push(`/${val}`)
      window.scrollTo(0, 0);
    }
  },
  computed: {},
  mounted () { }
};
</script>
<style scoped  lang="less">
@media (min-width: 992px) {
  /deep/ .col-lg-2 {
    flex: 0 0 14.28%;
    max-width: 14.28%;
  }
}

</style>
<style lang="less" scoped>
/deep/.ivu-tooltip-inner {
  background-color: black !important;
  min-height: 24px !important;
  padding: 4px !important;
}
/deep/.ivu-tooltip-arrow {
  border-right-color: black !important;
}
.footer {
  padding: 88px 0px 25px;
  background: black;
  .logo {
    max-width: 100px;
  }
  p {
    font-family: Louis George Cafe;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    font-weight: bold;
  }
  .f-content {
    margin: 20px 0px 40px;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .learn-more {
    li {
      line-height: 30px;
      a {
        font-family: Louis George Cafe;
        font-size: 14px;
        line-height: 16px;
        color: #fff;
      }
      a:hover
      // .router-link-exact-active
      {
        // color: @primaryColor;
      }
    }
  }
  .community {
    padding-top: 5px;
    margin-right: -20px;
    a {
      color: white;
      font-size: 22px;
      margin-right: 10px;
      img {
        width: 20px;
        // height: 20px;
        display: inline-block;
      }
      &:hover {
          img {
            filter: contrast(1.5);
          }
        }
    }
  }
  .footer-bottom {
    font-size: 16px;
    line-height: 24px;
    margin-top: 60px;
    color: #cccccc;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    max-width: none;
    padding: 0px 50px;
  }
  .col-md-12 {
    text-align: center;
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .col-md-12 {
    margin-bottom: 50px;
  }
  .col-xs-6 {
    width: 50%;
  }
  .container {
    max-width: none;
    padding: 0px 30px;
  }
  .col-xs-12 {
    text-align: left;
  }
  .footer {
    .community {
      a {
        display: inline-block;
        margin: 0px 15px 15px 0px;
      }
    }
  }
}
</style>
