const setTheDecimal = (val, num = 2) => {
  if (val === undefined) return '';
  const arr = String(val).split('.');
  let integer = Number(arr[0]).toLocaleString();
  integer = integer.indexOf('-') > -1 ? integer.split('-')[1] : integer;
  const decimals =  arr[1] ? arr[1].substring(0, num) : '';
  if (decimals) {
    if (decimals.length === 1) {
      return `${integer}.${decimals}0`;
    }
    if (decimals.length > 1) {
      return `${integer}.${decimals}`
    }
  }
  return `${integer}.00`;
}

const filtersMap = {
  setTheDecimal (val, num) {
    return setTheDecimal(val, num)
  }
};
export const filters = {
	install (Vue) {
		for (let key in filtersMap) {
			Vue.filter(key, filtersMap[key]);
		}
	}
};