export const ROUSDddress = '0x9236a4c8FDd9Fe918f8637BA75e735d264a890A3'
export const ROCddress = '0xE0402e87b4A622F35095C3e26cb63dc59A4DA2b1' 
export const PRddress = '0x54937f97fd23fc84460db24f8b6754c8f11af5ab'
export const DOddress = '0x424BC5b286fcf836d2c857Bd7818F1b3d0810630'

export const web3url = 'https://ropsten.infura.io/v3/27e484dcd9e3efcfd25a83a78777cdf1';

export default {
  ROUSDddress,
  ROCddress,
  PRddress,
  DOddress,
  web3url,
  env: 3
}