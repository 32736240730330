import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import i18n from './i18n'
// import 'font-awesome/css/font-awesome.min.css'
import './unitls'
import _ from 'lodash'
import { filters } from './unitls/filter';

Vue.config.productionTip = false;
Vue.use(filters);
Vue.prototype._ = _
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
