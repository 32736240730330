<template>
  <div class=WordSection1>

<p class=MsoNormal style='text-align:left; margin-bottom: 20.0pt'><span class=SpellE><span lang=EN style='font-size:30.0pt;
font-family:"ParmaPetit",serif;mso-fareast-font-family:"ParmaPetit";color:#085DA3;'>ROC sale disclaimer</span></span></p>

<p class=MsoNormal style=''><span>The Purchaser represents and warrants that the Purchaser:<o:p></o:p></span></p>

<p class=MsoNormal style=''><span>1. will not, directly or indirectly, transfer any Tokens into the United States, or to or for the account or benefit of any U.S. person; and will not directly or indirectly transfer any Tokens into any other countries if under the local laws such transfer is forbidden;<o:p></o:p></span></p>

<p class=MsoNormal style=''><span>2. acknowledges and agrees that the Company is not dealing in a financial product;<o:p></o:p></span></p>

<p class=MsoNormal style=''><span>3. acknowledges that the Tokens are not registered with any government agency, are not offered as a financial product or derivative or managed investment scheme, and that investments in cryptocurrency tokens, such as Tokens, are by their nature highly speculative and risky, and Tokens may lose all value; <o:p></o:p></span></p>

<p class=MsoNormal style=''><span>4. acknowledges and agrees that the Company has no liability for, nor the ability to recover a Purchaser’s Tokens if the Purchaser’s private key is forgotten, lost, stolen, misplaced or otherwise inaccessible to the Purchaser;<o:p></o:p></span></p>

<p class=MsoNormal style=''><span>5. has the knowledge and experience in cryptocurrency trading such that the Purchaser is capable of evaluating the merits and risks of applying for, holding or transferring Tokens; <o:p></o:p></span></p>

<p class=MsoNormal style=''><span>6. has conducted an investigation into Tokens, has made all reasonable enquiries, and is not relying wholly on the disclosure documents in deciding to purchase Tokens; <o:p></o:p></span></p>

<p class=MsoNormal style=''><span>7. has taken, or has had the opportunity to take and has elected not to take, independent accounting and legal advice concerning the nature and the Purchaser’s personal circumstances.<o:p></o:p></span></p>


</div>

</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.u-header__section {
  background: #000;
}
.WordSection1 {
  padding: 160px 50px 80px;
  background: #fff;
}
.MsoNormal {
  font-family: 'Louis George Cafe';
  font-size: 16px;
  line-height: 24px;
  color: #818E9E;
  background: #fff;
  margin-bottom: 14.0pt;
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
  }
}
</style>
