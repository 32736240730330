import Web3 from "web3";
import BigNumber from 'bignumber.js'

// import DOABI from '../contracts/DO.json'
import ROCABI from '../contracts/ROC.json'
import ROUSDABI from '../contracts/RoUSD.json'
import PRABI from '../contracts/Reserve.json'
import DOABI from '../contracts/DO.json'

// 测试
import config from './config.dev';
const web3_env = '3';
export const ROUSDddress = config.ROUSDddress;
export const ROCddress = config.ROCddress;
export const PRddress = config.PRddress;
export const DOddress = config.DOddress;

// https://docs.walletconnect.org/quick-start/dapps/web3-provider
// Mainnet (1), Ropsten (3), Rinkeby(4), Goerli (5) and Kovan (42)
// https://mainnet.infura.io/v3/523f57e2a07d4cc69c16629e498e650b
window.web3Provider = new Web3(new Web3.providers.HttpProvider(config.web3url));
import WalletConnectProvider from "@walletconnect/web3-provider";
const walletProvider = () => {
  return new WalletConnectProvider({
    rpc: {
      3: config.web3url,
    }
  })
}
// coinbase的钱包链接 https://walletlink.org/#/
// 断开链接 walletLink.disconnect() || ethereum.close()
import WalletLink from 'walletlink'
export const coinbaseProvide = () => {
  const walletLink = new WalletLink({
    appName: 'APP_NAME',
    appLogoUrl: 'https://example.com/logo.png',
    darkMode: false
  })
  return walletLink.makeWeb3Provider(config.web3url, config.env)
}

// 小狐狸连接 https://docs.metamask.io/guide/
// 调用方法 await MateMaskethereum.request({ method: 'eth_requestAccounts' });
export const MateMaskProvider = () => {
  if (typeof window.ethereum !== 'undefined'|| (typeof window.web3 !== 'undefined')) {
    return window.ethereum || window.web3.currentProvider
  }
}


// 判断是否安装了小狐狸
export const isMetamask = (typeof window.ethereum !== 'undefined' || (typeof window.web3 !== 'undefined'))
export const connect = async (val = 'matemask', isAcount = false) => {
  let type = ''
  if (val === 'matemask') {
    type = MateMaskProvider()
  } else if (val == 'coinbase') {
    type = coinbaseProvide()
  } else {
    type = walletProvider()
  }

  localStorage.setItem('loginType', val)
  // https://ropsten.infura.io/v3/523f57e2a07d4cc69c16629e498e650b
  if (isAcount) {
      window.web3Provider = new Web3(type);
      loginOut(type)
  } else {
    type.enable().then(async data => {
      window.web3Provider = new Web3(type);
      console.log(window.web3Provider , 'window.web3Provider ')
      const accounts = await window.web3Provider.eth.getAccounts()
      const chainID = await window.web3Provider.eth.getChainId()
      localStorage.setItem('account', accounts[0])
      localStorage.setItem('chainID', chainID)
      loginOut(type) 
      // console.log(getQueryVariable('type'), 'xxxxxxxxxxxx')
      window.location.href = `${window.location.protocol}//${window.location.host}/balances?type=do`
      // router.push('/balances?type=' + getQueryVariable('type'))
    })
  }
}


// 退出
export const loginOut = (provider) => {
  MateMaskProvider().on("accountsChanged",(accounts) => {
    // console.log(accounts, '---------');
    if (accounts.length < 1) {
      localStorage.removeItem('loginType')
      localStorage.removeItem('account')
      window.location.href = `${window.location.protocol}//${window.location.hostname}`
      // connect(loginType, false)
    } else {
      if (localStorage.getItem('account') !== accounts[0]) {
        localStorage.setItem('account', accounts[0])
        console.log('用户切换的时候刷新页面')
        window.location.reload()
      }
    }
    // window.location.reload();
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    if (localStorage.getItem('chainID') !== chainId) {
      localStorage.setItem('chainID', chainId)
      console.log('网络切换的时候刷新页面')
      window.location.reload();

    }
  });

  // Subscribe to session disconnection
  provider.on("disconnect", (code, reason) => {
    console.log(code, reason, '33333333333333');
  });
}

// 合约方法 rousd
export const ROUSDContract = async () => {
  const contract = await new window.web3Provider.eth.Contract(ROUSDABI.abi, config.ROUSDddress)
  return contract
}
// 合约方法 roc
export const ROCContract = async () => {
  const contract = await new window.web3Provider.eth.Contract(ROCABI.abi, config.ROCddress)
  return contract
}
// 合约方法 PR
export const PRContract = async () => {
  const contract = await new window.web3Provider.eth.Contract(PRABI.abi, config.PRddress)
  return contract
}
// 合约方法 do
export const DOContract = async () => {
  const contract = await new window.web3Provider.eth.Contract(DOABI.abi, config.DOddress)
  return contract
}

// 获取rousdc的资产
export const rousdBlance = (address) => {
  return ROUSDContract().then(async data => {
    return getDisplayBalance(new BigNumber(await data.methods.balanceOf(address).call()))
  })
}

// 获取do的资产
export const doBlance = (address) => {
  return DOContract().then(async data => {
    return getDisplayBalance(new BigNumber(await data.methods.balanceOf(address).call()))
  })
}

// 获取roc的资产
export const rocBlance = (address) => {
  return ROCContract().then(async data => {
    return getDisplayBalance(new BigNumber(await data.methods.balanceOf(address).call()))
  })
}

// 获取roc的平均价格
export const RocPrice = () => {
  return PRContract().then(async data => {
    return await data.methods.getAveragePriceOfROC().call()
  })
}

// 通过rousd兑换roc的方法
export const RousdToRoc = (amount) => {
  return PRContract().then(async data => {
    return await data.methods.estimateRoUSDAmountFromROC(amount).call()
  })
}

// 判断是否授权roc
export const isApproveRoc = (account) => {
  return ROCContract().then(async data => {
    // console.log('RocToDO',data)
    return await data.methods.allowance(account, PRddress).call()
  })
}

// 判断是否授权Do
export const isApproveDo = (account) => {
  return DOContract().then(async data => {
    // console.log('RocToDO',data)
    return await data.methods.allowance(account, PRddress).call()
  })
}

// 授权roc
export const approveRoc = (account) => {
  return ROCContract().then(async data => {
    return await data.methods.approve(PRddress, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({ from: account})
  })
}

// 通过roc兑换do的方法
export const RocToDO = (amount, account) => {
  return PRContract().then(async data => {
    console.log('RocToDO',data)
    return await data.methods.mintDOWithExactAmountOfROC(amount).send({from: account})
  })
}

// 通过do兑换roc的方法
export const DoToRoc = (amount) => {
  return PRContract().then(async data => {
    console.log('DoToRoc',data)
    return await data.methods.mintExactAmountOfDO(amount).call()
  })
}

// 获取orders
export const getDOOrders = (account, amount) => {
  return PRContract().then(async data => {
    // console.log('RocToDO',data)
    return await data.methods.loanMap(account, amount).call()
  })
}




// 格式化工具18位数转正常的数字
export const getDisplayBalance = (balance, decimals = 18) => {
  // balance = new BigNumber(balance)
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals))
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4)
  } else {
    return displayBalance.toFixed(6,1)
  }
}

// 防抖

export const debounce = (fn,delay) =>{
  let timer = null //借助闭包
  return function() {
      if(timer){
          clearTimeout(timer) 
      }
      timer = setTimeout(fn,delay) // 简化写法
  }
}

// 转bignumber
export const BigNum = (val) => {
  return new BigNumber(val)
}

// 转成18位数的数字
export const getFullDisplayBalance = (balance, decimals = 18) => {
  return balance.multipliedBy(new BigNumber(10).pow(decimals)).toFixed()
}

// js获取路由参数
export const getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
}

// 解决科学计数法
export const toolNumber = (num_str) => {
  num_str = num_str.toString();
  if (num_str.indexOf("+") != -1) {
    num_str = num_str.replace("+", "");
  }
  if (num_str.indexOf("E") != -1 || num_str.indexOf("e") != -1) {
    var resValue = "",
      power = "",
      result = null,
      dotIndex = 0,
      resArr = [],
      sym = "";
    var numStr = num_str.toString();
    if (numStr[0] == "-") {
      // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
      numStr = numStr.substr(1);
      sym = "-";
    }
    if (numStr.indexOf("E") != -1 || numStr.indexOf("e") != -1) {
      var regExp = new RegExp(
        "^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$",
        "ig"
      );
      result = regExp.exec(numStr);
      if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
      }
      if (!resValue && !power) {
        return false;
      }
      dotIndex = resValue.indexOf(".") == -1 ? 0 : resValue.indexOf(".");
      resValue = resValue.replace(".", "");
      resArr = resValue.split("");
      if (Number(power) >= 0) {
        var subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (var i = 0; i <= power - subres.length; i++) {
          resArr.push("0");
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, ".");
        }
      } else {
        power = power.replace("-", "");
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (var i = 0; i < power - dotIndex; i++) {
          resArr.unshift("0");
        }
        var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, ".");
      }
    }
    resValue = resArr.join("");

    return sym + resValue;
  } else {
    return num_str;
  }
}


// 刷新页面后重新激活链接
if (localStorage.getItem('account')) {
  const loginType = localStorage.getItem('loginType')
  const account = localStorage.getItem('account')
  connect(loginType, account)
} else {
  console.log('用户退出了')
}

