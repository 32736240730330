<template>
  <header
    :class="isTransparent ? 'u-header--bg-transparent' : ''"
    class="u-header u-header--full-container u-header--abs-top u-header--show-hide-lg u-header--toggle-section-lg"
    id="header"
  >
    <div class="header-contact" :style="{background: $route.path === '/do' || $route.path === '/wallet' || $route.path === '/balances' || $route.path === '/getRoc' || $route.path === '/getDO' || $route.path === '/legal/disclaimer' || $route.path === '/legal/roc/disclaimer' || $route.path === '/legal/burn/disclaimer' || $route.path === '/legal/token' ? '#000' : ''}">
      <div class="container-fluid">
        <a
          href="javascript:;"
          id="dropdownMegaMenuWithDropdown1"><span>whitepaper<i class="fa fa-angle-down" style="font-size: 14px"></i
        ></span>
        <!-- href="/Roxe Chain White Paper.pdf" target="_blank" -->
          <ul
                  class="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer"
                  id="dropdownSubMenuWithDropdown2"
                  style="max-width: 86px;min-width: 86px;"
                >
                  <li>
                    <router-link
                      class="nav-link u-header__sub-menu-nav-link"
                      to="/Roxe Chain White Paper.pdf"
                      tag="a"
                      target="_blank"
                      >Roxe chain</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="nav-link u-header__sub-menu-nav-link"
                      to="/Daollar Protocol White Paper.pdf"
                      tag="a"
                      target="_blank"
                      >Daollar</router-link
                    >
                  </li>
                </ul>        
        </a>
        <!-- <span><img src="./img/wechat.svg"/></span> -->
        <span><a href="https://twitter.com/RoxeChain" target="_blank"><img src="./img/twitter.svg"/></a></span>
        <!-- <span><img src="./img/facebook.svg"/></span> -->
        <span><a href="https://t.me/roxechainglobal" target="_blank"><img src="./img/telegram.svg"/></a></span>
        <span><a href="https://medium.com/roxechain" target="_blank"><img src="./img/m.svg"/></a></span>
        <span><a href="https://discord.gg/d3mDxd6th7" target="_blank"><img src="./img/Union.svg"/></a></span>
      </div>
    </div>
    <div class="u-header__section" :style="{background: $route.path === '/do' || $route.path === '/wallet' || $route.path === '/balances' || $route.path === '/getRoc' || $route.path === '/getDO' || $route.path === '/legal/disclaimer' ||$route.path === '/legal/roc/disclaimer' || $route.path === '/legal/burn/disclaimer' || $route.path === '/legal/token'  ? '#000' : ''}">
      <div class="container-fluid" id="logoAndNavWithDropdown">
        <nav
          class="js-mega-menu navbar navbar-expand-lg u-header__navbar u-header__navbar--no-space"
        >
          <router-link
            aria-label="Front"
            class="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
            to="/"
          >
            <img alt src="../assets/images/roxe_chain.png" />
          </router-link>
          <button
            aria-controls="navBarWithDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            class="navbar-toggler btn u-hamburger"
            data-target="#navBarWithDropdown"
            data-toggle="collapse"
            type="button"
          >
            <span class="u-hamburger__box" id="hamburgerTriggerWithDropdown">
              <span class="u-hamburger__inner"></span>
            </span>
          </button>
          <div
            class="collapse navbar-collapse u-header__navbar-collapse"
            id="navBarWithDropdown"
            ref="headerCollapse"
          >
            <ul class="navbar-nav u-header__navbar-nav">
              <!--<li
                class="nav-item hs-has-sub-menu u-header__nav-item"
                data-animation-in="slideInUp"
                data-animation-out="fadeOut"
                data-event="hover"
              >
                <a
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-labelledby="dropdownSubMenuWithDropdown"
                  class="nav-link u-header__nav-link"
                  href="javascript:;"
                  id="dropdownMegaMenuWithDropdown"
                  >{{ $t('Home.Nav.Trade') }}<i class="fa fa-angle-down" style="font-size: 14px"></i
                ></a>
                <ul
                  aria-labelledby="dropdownMegaMenuWithDropdown"
                  class="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer"
                  id="dropdownSubMenuWithDropdown"
                  style="min-width: 130px"
                >
                  <li>
                    <a
                      class="nav-link u-header__sub-menu-nav-link"
                      href="https://www.bhex.cc/zh-cn/exchange/ROC/USDT"
                      target="_blank"
                      >BHEX
                    </a>
                  </li>
                  <li>
                    <a
                      class="nav-link u-header__sub-menu-nav-link"
                      href="https://www.bkex.com/trade/ROC_USDT"
                      target="_blank"
                      >BKEX
                    </a>
                  </li>
                  <li>
                    <a
                      class="nav-link u-header__sub-menu-nav-link"
                      href="https://global.bittrex.com/Market/Index?MarketName=USDT-ROC"
                      target="_blank"
                      >Bittrex
                    </a>
                  </li>
                  <li>
                    <a
                      class="nav-link u-header__sub-menu-nav-link"
                      href="https://app.uniswap.org/#/swap?inputCurrency=0xda0b283316b1f998a0be6c371dc7b4f446cd548e&outputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
                      target="_blank"
                      >Uniswap
                    </a>
                  </li>
                </ul>
              </li>-->
              <!-- <li
                class="nav-item hs-has-sub-menu u-header__nav-item"
                data-animation-in="slideInUp"
                data-animation-out="fadeOut"
                data-event="hover"
              >
                <a
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-labelledby="dropdownSubMenuWithDropdown"
                  class="nav-link u-header__nav-link"
                  href="javascript:;"
                  id="dropdownMegaMenuWithDropdown1"
                  >Build<i class="fa fa-angle-down" style="font-size: 14px;"></i
                ></a>
                <ul
                  aria-labelledby="dropdownMegaMenuWithDropdown1"
                  class="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer"
                  id="dropdownSubMenuWithDropdown"
                  style="min-width: 110px;"
                >
                  <li>
                    <a href="https://scan.rocpro.me/" target="_blank" class="nav-link u-header__sub-menu-nav-link">Explorer</a>
                  </li>
                </ul>
              </li> -->
              <li class="nav-item u-header__nav-item">
                <a
                  href="https://scan.rocpro.me/"
                  target="_blank"
                  class="nav-link self-nav"
                  >{{ $t('Home.Nav.Explorer') }}</a
                >
              </li>
              <li
                :class="$route.path === '/home' ? 'active' : ''"
                class="nav-item u-header__nav-item"
              >
                <router-link class="nav-link u-header__nav-link" to="/home"
                  >{{ $t('Home.Nav.Daollar') }}</router-link
                >
              </li>
              <li
                :class="$route.path === '/supernodes' ? 'active' : ''"
                class="nav-item u-header__nav-item"
              >
                <router-link
                  class="nav-link u-header__nav-link"
                  to="/supernodes"
                  >{{ $t('Home.Nav.Supernodes') }}</router-link
                >
              </li>
              <li
                class="nav-item hs-has-sub-menu u-header__nav-item"
                data-animation-in="slideInUp"
                data-animation-out="fadeOut"
                data-event="hover"
              >
                <a
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-labelledby="dropdownSubMenuWithDropdown"
                  class="nav-link u-header__nav-link"
                  href="javascript:;"
                  id="dropdownMegaMenuWithDropdown"
                  >{{ $t('Home.Nav.Info') }}<i class="fa fa-angle-down" style="font-size: 14px"></i
                ></a>
                <ul
                  aria-labelledby="dropdownMegaMenuWithDropdown"
                  class="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer"
                  id="dropdownSubMenuWithDropdown"
                  style="min-width: 130px"
                >
                  <li>
                    <router-link
                      class="nav-link u-header__sub-menu-nav-link"
                      to="/resources"
                      >{{ $t('Home.Nav.Resources') }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      class="nav-link u-header__sub-menu-nav-link"
                      to="/about"
                      >{{ $t('Home.Nav.AboutUs') }}</router-link
                    >
                  </li>
                  <!--<li>
                    <router-link
                      class="nav-link u-header__sub-menu-nav-link"
                      to="/blog"
                      >{{ $t('Home.Nav.Blog') }}</router-link
                    >
                  </li>-->
                </ul>
              </li>

              <li
                class="nav-item hs-has-sub-menu u-header__nav-item"
                data-animation-in="slideInUp"
                data-animation-out="fadeOut"
                data-event="hover"
              >
                <a
                  aria-expanded="false"
                  aria-haspopup="true"
                  aria-labelledby="dropdownSubMenuWithDropdown"
                  class="nav-link u-header__nav-link"
                  href="javascript:;"
                  id="dropdownMegaMenuWithDropdown1"
                  >{{initLanguage}}<i class="fa fa-angle-down" style="font-size: 14px"></i
                ></a>
                <ul
                  aria-labelledby="dropdownMegaMenuWithDropdown1"
                  class="hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer"
                  id="dropdownSubMenuWithDropdown"
                  style="min-width: 130px"
                >
                  <li>
                    <a href="javascript:void(0)" class="nav-link u-header__sub-menu-nav-link"  @click="changeLanguage('en')">EN</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" class="nav-link u-header__sub-menu-nav-link"  @click="changeLanguage('zh-cn')">简体中文</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      initLanguage: localStorage.getItem('language') === 'zh-cn' ? "简体中文" : "EN"
    };
  },
  components: {},
  methods: {
    handleDocumentClick(e) {
      if (!this.$el.contains(e.target)) {
        if ($("#navBarWithDropdown").hasClass("show")) {
          $("#navBarWithDropdown").removeClass("show");
          $(".u-hamburger").addClass("collapsed").attr("aria-expanded", false);
        }
        if ($(".hs-has-sub-menu").hasClass("hs-sub-menu-opened")) {
          $(".hs-has-sub-menu").removeClass("hs-sub-menu-opened");
          $(".u-header__sub-menu").css("display", "none");
        }
      }
    },
    changeLanguage (val) {
      this.$i18n.locale = val
      this.initLanguage = val === 'en' ? 'EN' : '简体中文'
      localStorage.setItem('language', val)
    }
  },
  computed: {
    //  通过路由改变header的颜色
    isTransparent() {
      return !!(this.$route.path === "/");
    },
  },
  watch: {},
  mounted() {
    document.addEventListener("click", this.handleDocumentClick);
  },
  destroyed() {
    document.removeEventListener("click", this.handleDocumentClick);
  },
};
</script>
<style lang="less" scoped>
.fa-angle-down {
  padding-left: 8px;
}
.navbar {
  height: 70px;
}
.header-contact {
  height: 55px;
  border-bottom: 1px solid #FFFFFF1A;
  &>div {
    text-align: right;
    a {
      position: relative;
      height: 40px;
      display: inline-block;
      #dropdownSubMenuWithDropdown2 {
        top:40px;
        visibility: inherit;
        padding-bottom: 15px;
        li {
          // padding: 15px 10px;
          text-align: left;
          a {
            padding: 0;
            font-size: 14px;
            padding: 15px 9px 0px 10px;
            // margin-bottom: 15px;
            color: #4F4F4F;
            &:hover {
              color: #085DA3;
            }
          }
        }
      }
      &:hover {
        #dropdownSubMenuWithDropdown2 {
          display: block;
        }
      }
    }
    span {
      line-height: 16px;
      color:#fff;
      cursor: pointer;
      // margin-right: 16px;
      img {
        margin-right: 8px;
        // &:last-child {
        //   margin-right: 0px;
        // }
        &:hover {
          filter: contrast(1.5);
        }
      }
      &:first-child {
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        height: 16px;
        display:inline-block;
        margin-top: 12px;
        font-family: ParmaPetit;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 15px;
      }
      &:last-child {
        img {
          margin-right: 1.7rem;
        }
      }
    }
  }
}
.u-header {
  z-index: 999;
}
.u-header__section {
  background-color: transparent;
  //  background: linear-gradient(180deg, #085da3 0%, #3a89ca 100%);
}
.container-fluid {
  padding: 0 3rem;
  padding-top: 15px;
}
.u-header__navbar-brand {
  > img {
    width: 150px;
    height: auto;
  }
}

.u-header__nav-link {
  color: #fff;
  border-bottom: 2px solid transparent;
  font-family: ParmaPetit;
  font-size: 20px;
  line-height: 18px;
}
.u-header__sub-menu-nav-link {
  color: #4f4f4f;
  font-size: 16px;
  &:hover {
    color: #085da3;
  }
}
.router-link-exact-active {
  color: #085da3;
}
.navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
  padding: 1.3rem 0 1.1rem;
  margin-right: 1.7rem;
}
.navbar-expand-lg .u-header__navbar-nav .u-header__nav-item {
  .sign-btn {
    display: inline-block;
    min-width: 86px;
    font-size: 16px;
  }
  // &:last-child {
  //   a {
  //     margin-right: 0px;
  //   }
  // }
}
.u-header .active > .u-header__nav-link,
.u-header__nav-item:focus .u-header__nav-link,
.u-header__nav-item:hover .u-header__nav-link {
  color: #fff;
}

.self-nav {
  color: #fff;
  font-family: ParmaPetit;
  font-size: 20px;
  margin-right: 1.5rem;
}
.u-header .active > .u-header__nav-link {
  border-bottom: 2px solid #fff;
}
#dropdownMegaMenuWithDropdown1:hover {
  #dropdownMegaMenuWithDropdown2 {
    display: block;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
    border-top: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@media (max-width: 991px) {
  .self-nav {
    padding: 1.3rem 0 1.1rem;
    padding-left: 1.5rem;
    color: #77838f;
    font-family: ParmaPetit;
    font-size: 20px;
  }
  .u-header__navbar {
    padding: 0.8rem 0 0;
  }
  .container-fluid {
    padding: 0;
  }
  .u-header__navbar-brand {
    padding-left: 20px;
    > img {
      width: 75px;
      margin-bottom: 0.8rem;
    }
  }
  .navbar-toggler {
    text-align: right;
    min-width: auto;
    margin-bottom: 0.8rem;
  }
  .u-hamburger__inner,
  .u-hamburger__inner:after,
  .u-hamburger__inner:before {
    background-color: #fff;
  }
  .u-hamburger:hover .u-hamburger__inner,
  .u-hamburger:hover .u-hamburger__inner:after,
  .u-hamburger:hover .u-hamburger__inner:before {
    background-color: #fff;
  }
  .u-header__nav-link {
    color: #77838f;
  }
  .u-header .active > .u-header__nav-link,
  .u-header__nav-item:focus .u-header__nav-link,
  .u-header__nav-item:hover .u-header__nav-link {
    color: #085da3;
  }
  .navbar-expand-lg .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0.1875rem solid #085da3;
  }
  .u-header .active > .u-header__nav-link {
    color: #085da3;
    border-color: transparent;
  }
  .u-header__navbar-collapse {
    background: #fff;
  }
  .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-right: 0;
  }
  .navbar-expand-lg .u-header__sub-menu:not(.u-header__promo) {
    border-left: 0;
    padding-left: 0;
  }
  .u-header__sub-menu-nav-link {
    padding-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .u-header--bg-transparent .u-header__navbar-nav {
    padding: 0.875rem 0;
  }
}
</style>
