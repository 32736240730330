<template>
  <div class='WordSection1'>
<p class=MsoNormal style='line-height:40px; text-align:left; margin-bottom: 20.0pt'><span class=SpellE><span lang=EN style='font-size:30.0pt;
font-family:"ParmaPetit",serif;mso-fareast-font-family:"ParmaPetit";color:#085DA3;'>Announcement: Roxe burning 9.9 billion ROC</span></span></p>
<p class='tit'>Dear Roxe users.</p>
<p class='MsoNormal'>In order to build ROC with a long-term value. Roxe plans to burn 9.9 billion ROC on May 18 (9.765 billion on Roxe Chain; 135 million on Ethereum). The total circulation of ROC would be 100 million after this burning (The original plan was to issue 10 billion ROC in total).</p>
<div class='grid'>
  <img src="../../assets/img/grid.jpeg"/>
</div>
<p class='MsoNormal'>Among the 100 million ROC, 35 million on  the main Roxe Chain will be used for Roxe Reserve (RR) account, super node staking and daily operation, etc. The remaining 65 million ROCs on Ethereum will be used for ecosystem build-up, R&D, financing, trading, circulation and price discovery, etc.</p>
<p class="MsoNormal mt20">Currently, ROCs are online on multiple trading markets such as BHEX (formerly Hobbit HBTC), BKEX Global, Bilaxy, Uniswap, and Mercurity.Finance.</p>
<p class='MsoNormal'>May 17, 2021</p>
<p class='MsoNormal mt10'>Roxe Community</p>
</div>

</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.tit{
  font-size: 18px;
  color: #4F4F4F;
  margin-bottom: 10px;
}
.u-header__section {
  background: #000;
}
.mt10{
  margin-top: 10px;
}
.mt20{
  margin-top: 20px;
  margin-bottom: 20px;
}
.grid {
  img{
    width: 100%;
  }
  margin-bottom: 30px;
  margin-top: 30px;
}
.WordSection1 {
  padding: 160px 50px 80px;
  background: #fff;
  min-height:700px;
}
.MsoNormal {
  font-family: 'Louis George Cafe';
  font-size: 16px;
  line-height: 24px;
  color: #818E9E;
  background: #fff;
  margin-bottom: 14.0pt;
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
  }
}
</style>
